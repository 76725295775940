/* tslint:disable */
/* eslint-disable */
/**
 * Torrents Stream Server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface UsageModel
 */
export interface UsageModel {
    /**
     *
     * @type {number}
     * @memberof UsageModel
     */
    totalDiskSpace: number
    /**
     *
     * @type {number}
     * @memberof UsageModel
     */
    freeDiskSpace: number
    /**
     *
     * @type {number}
     * @memberof UsageModel
     */
    usedTorrentSpace: number
}

export function UsageModelFromJSON(json: any): UsageModel {
    return UsageModelFromJSONTyped(json, false)
}

export function UsageModelFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean
): UsageModel {
    if (json === undefined || json === null) {
        return json
    }
    return {
        totalDiskSpace: json['totalDiskSpace'],
        freeDiskSpace: json['freeDiskSpace'],
        usedTorrentSpace: json['usedTorrentSpace'],
    }
}

export function UsageModelToJSON(value?: UsageModel | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        totalDiskSpace: value.totalDiskSpace,
        freeDiskSpace: value.freeDiskSpace,
        usedTorrentSpace: value.usedTorrentSpace,
    }
}
