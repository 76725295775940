/* tslint:disable */
/* eslint-disable */
/**
 * Torrents Stream Server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface ProviderErrorModel
 */
export interface ProviderErrorModel {
    /**
     *
     * @type {string}
     * @memberof ProviderErrorModel
     */
    error: string
    /**
     *
     * @type {string}
     * @memberof ProviderErrorModel
     */
    provider: string
}

export function ProviderErrorModelFromJSON(json: any): ProviderErrorModel {
    return ProviderErrorModelFromJSONTyped(json, false)
}

export function ProviderErrorModelFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean
): ProviderErrorModel {
    if (json === undefined || json === null) {
        return json
    }
    return {
        error: json['error'],
        provider: json['provider'],
    }
}

export function ProviderErrorModelToJSON(
    value?: ProviderErrorModel | null
): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        error: value.error,
        provider: value.provider,
    }
}
