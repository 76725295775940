/* tslint:disable */
/* eslint-disable */
/**
 * Torrents Stream Server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface SuccessModel
 */
export interface SuccessModel {
    /**
     *
     * @type {boolean}
     * @memberof SuccessModel
     */
    success: boolean
}

export function SuccessModelFromJSON(json: any): SuccessModel {
    return SuccessModelFromJSONTyped(json, false)
}

export function SuccessModelFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean
): SuccessModel {
    if (json === undefined || json === null) {
        return json
    }
    return {
        success: json['success'],
    }
}

export function SuccessModelToJSON(value?: SuccessModel | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        success: value.success,
    }
}
